import categoryCard from "./components/category-card/index.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import product from '@/components/product/index.vue'
import customBreadcrumbs from '@/components/custom-breadcrumbs/index.vue'
import VueHead from 'vue-head'
import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)
Vue.use(VueHead)

export default {
  name: "main-catalog",
  components: {
    categoryCard,
    product,
    customBreadcrumbs
  },
  data(){
    return{
      firstCategory : null,
      allProductsArrParent: [],
      showAll: false,
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
        micro: {
          prices: ''
        }
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
      ]
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    },
    script: function() {
      return [
        {
          t: 'application/ld+json',
          i: this.params.micro.prices
        },
      ]
    }
  },
  computed:{
    ...mapGetters({
      currentCategory: `category/subCategories`,
      allProducts: `category/allProductsList`,
      categoryLoader:`category/categoryLoader`,
      breadcrumbsList:`category/breadcrumbsList`,
    })
  },
  created() {
    // if(this.$route.params.slug){
    this.allProductsArrParent = []
    this.showAll = false
    // setTimeout(() => {
    //   this.getAllProducts()
    //   this.showAll = true;
    // }, 1000)
    this.fetchCategory(this.$route.params.slug).then(()=>{
      this.setFirstCategory(this.currentCategory)
    })

    // }else{
    //   console.log('1111')
    // }

  },
  watch:{
    '$route.params.slug': function(slug) {
        this.fetchCategory(slug)
    },
    // '$route'(newVal) {
    //   if(newVal) {
    //     this.allProductsArrParent = []
    //     this.showAll = false
    //     setTimeout(() => {
    //       this.getAllProducts()
    //       this.showAll = true;
    //     }, 1000)
    //   }
    // },
    currentCategory: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setMicro()
          this.setMeta()
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setFirstCategory:'category/SET_FIRST_CATEGORY'
    }),
    ...mapActions({
      fetchCategory: `category/GET_SUB_CATEGORIES`
    }),
    // getAllProducts() {
    //   let localArr = [];
    //   setTimeout(() => {
    //     if(this.currentCategory.subcategories.length) {
    //       this.currentCategory.subcategories.forEach((mainSubCat) => {
    //         if(mainSubCat.subcategories.length) {
    //           mainSubCat.subcategories.forEach((subCat) => {
    //             if(subCat.products.length) {
    //               subCat.products.forEach((product) => {
    //                 localArr.push(product)
    //               })
    //             }
    //             if(!subCat.products.length && subCat.subcategories.length) {
    //               subCat.subcategories.forEach((nextSubCat) => {
    //                 nextSubCat.products.forEach((product) => {
    //                   localArr.push(product)
    //                 })
    //               })
    //             }
    //             if(!subCat.subcategories) {
    //               localArr = []
    //             }
    //           })
    //         } else {
    //           mainSubCat.products.forEach((product) => {
    //             localArr.push(product)
    //           })
    //         }
    //       })
    //       localArr.forEach((product) => {
    //         this.allProductsArrParent.push(product)
    //       })
    //     }
    //   }, 500)

    //   console.log('doneParent',this.allProductsArrParent)
    // },
    setMeta() {
      this.params.title = this.currentCategory.meta_title !== '' ? this.currentCategory.meta_title : 'ICHIBAN STORE',
        this.params.description = this.currentCategory.meta_description !== '' ? this.currentCategory.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE',
        this.params.keywords = this.currentCategory.meta_keywords,
        this.params.image = this.currentCategory.image !== '' ? this.currentCategory.image : 'https://ichibanstore.jp/logo.png'
      this.$emit('updateHead')
    },
    setMicro() {
      this.params.micro.prices = this.currentCategory.micro.prices
    },
    setCategory(){
    }
  }
}